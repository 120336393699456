
import { Component, Vue, Prop } from "vue-property-decorator";
import { Chart } from "highcharts-vue";

@Component({
  components: {
    highchart: Chart,
  },
})
export default class BaseChart extends Vue {
  $refs!: {
    // eslint-disable-next-line
    highchart: InstanceType<typeof Chart>;
  };
  @Prop({ required: true })
  // eslint-disable-next-line
  chartData!: any;

  get chartOptions() {
    return {
      ...this.chartData,
      credits: {
        enabled: false,
      },

      tooltip: {
        shared: true,
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        y: 0,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
        itemStyle: {
          lineHeight: "0px",
        },
      },
    };
  }

  mounted() {
    window.addEventListener("resize", this.redraw);
    this.redraw();
  }
  redraw() {
    this.$refs.highchart.chart.update(this.chartOptions, true);
    this.$refs.highchart.chart.setSize();
  }
}
