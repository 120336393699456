
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { TransportStore, UserStore } from "@/store/modules";
import colors from 'quasar/src/utils/colors.js';;
import ref from "vue";
import UserTable from "@/components/UserTable.vue";
import { filter } from "vue/types/umd";

@Component({
  components: { ContentHeader, AppFooter, UserTable },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class TransportScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  searchText = null;

  openDeleteDialog = false;
  transportId = "";

  currentPage = 1;
  tableLoading = false;
  totalPages = 0;
  tabIndex = "agents";

  splitterModel = 10;

  async created() {
    this.tableLoading = true;

    await this.fetchPaginatedUserList();

    this.tableLoading = false;
    console.log(this.currentPage);
    console.log(colors.getPaletteColor("primary"));
  }

  async fetchPaginatedUserList(tabIndex: any = null, oldIndex: any = null) {
    this.tableLoading = true;
    let filters: any = {};
    console.log(tabIndex);

    if (this.tabIndex == "agents") {
      filters["role"] = "Agent";
    }

    if (this.tabIndex == "entrier") {
      filters["role"] = "Entrier";
    }

    if (this.tabIndex == "agent_entrier") {
      filters["role"] = "AgentAndEntrier";
    }

    if (this.searchText) {
      await UserStore.getPaginatedUserList({
        page: 1,
        searchString: this.searchText,
      });
      this.currentPage = 1;
    } else {
      await UserStore.getPaginatedUserList({
        page: this.currentPage,
        filters: filters,
      });
    }
    this.paginatedUserList;
    this.totalPages = this.paginatedUserList.total_pages;
    this.currentPage = this.paginatedUserList.page;
    this.tableLoading = false;
  }

  // async fetchPaginatedTransportList() {
  //   if (this.searchText) {
  //     console.log("searchtext");
  //     await TransportStore.getPaginatedTransportList({
  //       page: 1,
  //       searchString: this.searchText,
  //     });
  //     this.currentPage = 1;
  //   } else {
  //     await TransportStore.getPaginatedTransportList({
  //       page: this.currentPage,
  //     });
  //   }

  //   this.paginatedTransportList;
  //   this.totalPages = this.paginatedTransportList.total_pages;
  //   this.currentPage = this.paginatedTransportList.page;
  // }

  get paginatedUserList() {
    console.log(UserStore.PaginatedUserList);
    return UserStore.PaginatedUserList;
  }

  dataColumn = [
    {
      name: "number",
      required: true,
      label: "Truck No.",
      align: "left",
      field: (row: any) => row.number,

      sortable: true,
    },
    {
      name: "quantity",
      align: "left",
      label: "Quantity (kg)",
      field: "quantity",
      sortable: true,
    },
    {
      name: "place",
      label: "From",
      align: "left",
      field: "place",
      sortable: true,
      sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
    },
    { name: "id", align: "left", label: "Action", field: "id", sortable: true },
  ];

  navigateToCreateUser() {
    this.$router.push({
      name: "CreateUser",
    });
  }

  navigateToEditTransport(id: any) {
    this.$router.push({
      name: "EditTransport",
      params: {
        id,
      },
    });
  }

  navigateToDashboard() {
    this.$router.push({
      name: "Dashboard",
    });
  }

  toggleDeleteDialog(transportId: any) {
    let state = this.openDeleteDialog;
    this.openDeleteDialog = !state;
    this.transportId = transportId;
  }

  async deleteTransportById() {
    await TransportStore.deleteTransportById({
      transportId: this.transportId,
    })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully deleted",
          color: "info",
        });
        // this.fetchPaginatedTransportList();
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }
}
