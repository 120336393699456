
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import {
  AdministrativeAreaStore,
  MarketStore,
  TraderStore,
} from "@/store/modules";
import type { Trader } from "@/store/model/trader";
import { removeFilesForPatch, getNestedFormData } from "@/utils/help.utils";

@Component({
  components: { ContentHeader, AppFooter },
})
export default class MmcMemberForm extends Vue {
  headerBackgroundUrl = "about.jpg";

  editId = 0;
  pageHeading = "";
  buttonLabel = "";

  imageData: any = null;
  previewImage: any = "";

  imageInput(event: any) {
    if (event && event.target) {
      this.imageData = event.target.files[0];
      const reader = new FileReader();
      if (this.imageData) {
        reader.readAsDataURL(this.imageData);
      }
      reader.onload = (e: any) => {
        this.previewImage = e.target.result;
      };
    }
  }

  async created() {
    if (this.$route.params.id) {
      this.editId = Number(this.$route.params.id);
      await TraderStore.getTraderByID(this.editId);
      if (this.tradersDataByIdData.company_logo) {
        this.previewImage = this.tradersDataByIdData.company_logo;
      }
    } else {
      this.tradersDataByIdData = {
        id: 0,
        market: "",
        focal_person: "",
        company_name: "",
        phone_number: "",
        office_number: "",
        province: "",
        district: "",
        palika: "",
        ward: "",
        company_logo: "",
        products: [],
      };
    }
  }

  get tradersDataByIdData() {
    return TraderStore.SingleTrader;
  }

  set tradersDataByIdData(value: Trader) {
    TraderStore.setTrader(value);
  }

  setHeading() {
    this.editId === 0
      ? (this.pageHeading = "Add")
      : (this.pageHeading = "Edit");
    return this.pageHeading;
  }

  setButtonLabel() {
    this.editId === 0
      ? (this.buttonLabel = "Save")
      : (this.buttonLabel = "Edit");
    return this.buttonLabel;
  }

  async onSubmit() {
    if (this.imageData) {
      this.tradersDataByIdData.company_logo = this.imageData;
    }
    if (this.pageHeading === "Add") {
      await this.createTrader();
    } else {
      await this.editTrader(this.editId);
    }
  }

  async editTrader(id: number) {
    removeFilesForPatch([
      {
        property: this.tradersDataByIdData,
        fieldName: "company_logo",
      },
    ]);
    const editFormData = new FormData();
    const editData = getNestedFormData(editFormData, this.tradersDataByIdData);
    await TraderStore.editTrader({ data: editData, id: id })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully Edited",
          color: "info",
        });
        this.$router.push({
          name: "Trader",
        });
      })
      .catch((error) => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }

  async createTrader() {
    const editFormData = new FormData();
    const editData = getNestedFormData(editFormData, this.tradersDataByIdData);
    await TraderStore.createTrader({ trader: editData })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully Edited",
          color: "info",
        });
        this.$router.push({
          name: "Trader",
        });
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }

  get marketList() {
    let markets = MarketStore.MarketList.map((x) => {
      return {
        label: x.name_en,
        value: x.id,
      };
    });
    return markets;
  }

  get provinceList() {
    let provinces = AdministrativeAreaStore.Provinces.map((x) => {
      return {
        label: x.name,
        value: x.sid,
      };
    });
    return provinces;
  }

  get districtList() {
    let districts = AdministrativeAreaStore.Districts.map((x) => {
      return {
        province: x.province,
        label: x.name,
        value: x.sid,
      };
    });
    districts = districts.filter(
      (x) => x.province == this.tradersDataByIdData.province
    );
    return districts;
  }

  get palikaList() {
    let palikas = AdministrativeAreaStore.Palikas.map((x) => {
      return {
        district: x.district,
        label: x.name,
        value: x.sid,
      };
    });
    palikas = palikas.filter(
      (x) => x.district == this.tradersDataByIdData.district
    );
    return palikas;
  }

  get commodityCategoryList() {
    let categories = MarketStore.CategoryList.map((x) => {
      return {
        label: x.name,
        value: x.sid,
      };
    });
    return categories;
  }

  get productsList() {
    let products = MarketStore.ProductList.map((x) => {
      return {
        category: x.category_id,
        label: x.name,
        value: x.sid,
      };
    });
    return products;
  }

  validatesPhoneNumber(phone_number: string) {
    return /(?:\(?\+977\)?)?[9][6-9]\d{8}|01[-]?[0-9]{7}/.test(phone_number);
  }

  async beforeMount() {
    this.tradersDataByIdData.market = MarketStore.MARKETPLACE_ID;

    await MarketStore.getMarketsList();
    await MarketStore.getProductCategoryList();
    await MarketStore.getProductList();
    await AdministrativeAreaStore.getProvinceS();
    await AdministrativeAreaStore.getDistrictList();
    await AdministrativeAreaStore.getPalikaList();
  }
}
