import { render, staticRenderFns } from "./TraderScreen.vue?vue&type=template&id=244288ad"
import script from "./TraderScreen.vue?vue&type=script&lang=ts"
export * from "./TraderScreen.vue?vue&type=script&lang=ts"
import style0 from "./TraderScreen.vue?vue&type=style&index=0&id=244288ad&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QTable,QInput,QIcon,QTd,QImg,QDialog,QCard,QCardSection,QAvatar,QCardActions});qInstall(component, 'directives', {ClosePopup});
