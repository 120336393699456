
import { Component, Vue } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: { ContentHeader },
})
export default class ReportScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  ProductListDownlading = "";
  isHomeScreen = true;
  tableLoading = false;
  asOf = "";
  currentDate = "";
  startDate = "";
  endDate = "";
  currentPage: any = "";
  totalPages: any = "";
  commodityCategoryModel = "";

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }
}
