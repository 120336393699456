
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { Transport } from "@/store/model/transport";
import {
  AdministrativeAreaStore,
  MarketStore,
  TransportStore,
  UserStore,
} from "@/store/modules";
import router from "@/router";
import { User, UserProfile } from "@/store/model/user";

@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class CreateTransportScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  navigationTitle = this.$t("addUser");

  user_profile: UserProfile = {} as UserProfile;
  user: User = {} as User;
  profile_picture: any = {};

  sectionLoading = false;
  submitButtonText = "Create";
  districtSelectDisabled = true;
  palikaSelectDisabled = true;

  async created() {
    const route_params = this.$route.params;
    await this.fetchProvinceList();
    await this.fetchRoleList();

    if (route_params.id) {
      this.navigationTitle = this.$t("editUser");
      this.submitButtonText = "Edit";
      this.sectionLoading = true;

      // await this.fetchTransportByID(route_params);

      this.sectionLoading = false;
    }
  }

  get provinceList() {
    return AdministrativeAreaStore.ProvinceList;
  }

  get districtList() {
    return AdministrativeAreaStore.DistrictList;
  }

  get palikaList() {
    return AdministrativeAreaStore.PalikaList;
  }

  get roleList() {
    return UserStore.UserRole;
  }

  async fetchRoleList() {
    await UserStore.getUserRoles();
  }

  // async fetchTransportByID(route_params: any) {
  //   const transportId = route_params.id;
  //   this.transport = await TransportStore.getTransportById({
  //     transportId: transportId,
  //   }).catch(() => {
  //     this.$q.notify({
  //       message: "There was an error while fetching the data.",
  //       color: "negative",
  //     });
  //   });

  //   // console.log("tt ", this.transport);
  // }

  navigateToAgentScreen() {
    this.$router.push({
      name: "Agent",
    });
  }
  async createUser() {
    // this.transport.market = MarketStore.MARKETPLACE_ID;
    let userFormData = new FormData();
    this.user_profile.profile_image = this.profile_picture;

    Object.keys(this.user_profile).forEach((key) =>
      userFormData.append(key, (this as any).user_profile[key])
    );
    Object.keys(this.user).forEach((key) =>
      userFormData.append(key, (this as any).user[key])
    );

    await UserStore.createUser({ userDetail: userFormData })
      .then(() => {
        this.$q.notify({
          message: "Created Sucessfully!",
          color: "positive",
        });
        // this.navigateToAgentScreen();
      })
      .catch(() => {
        this.$q.notify("An error occurred while performing this action.");
      });
  }

  async fetchProvinceList() {
    await AdministrativeAreaStore.getProvinceList();
  }

  async fetchDistrictByProvinceId() {
    let filters: any = {};

    filters["province"] = (this as any).user_profile.province;

    await AdministrativeAreaStore.getDistrictByProvinceId({
      filters: filters,
    });

    this.districtSelectDisabled = false;
  }

  async fetchPalikaByDistrictId() {
    let filters: any = {};

    filters["district"] = (this as any).user_profile.district;

    await AdministrativeAreaStore.getPalikaByDistrictId({
      filters: filters,
    });

    this.palikaSelectDisabled = false;
  }
}
