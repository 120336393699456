
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { MarketStore, MMCMembersStore } from "@/store/modules";
import { QTablePagination } from "@/store/model/page";

@Component({
  components: { ContentHeader, AppFooter },
})
export default class MmcMemberList extends Vue {
  headerBackgroundUrl = "about.jpg";
  searchText = "";
  openDeleteDialog = false;
  deleteId = 0;
  currentPage = 1;
  tableLoading = false;
  totalPages = 0;

  created() {
    this.tableLoading = true;
    this.filterValue();
    this.tableLoading = false;
  }

  get paginatedMmcMembers() {
    return MMCMembersStore.PaginatedMMCMembers;
  }

  get pagination() {
    return MMCMembersStore.QTablePaginationData;
  }

  set pagination(data: QTablePagination) {
    MMCMembersStore.setMmcPagination(data);
  }

  onRequest = async (props: any) => {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const filter = props.filter;

    this.tableLoading = true;

    const ordering = `${descending ? "-" : ""}${sortBy}`;

    const queryParams = {
      page: page,
      search: filter,
      market: MarketStore.MARKETPLACE_ID,
      ordering: ordering,
      pageSize: rowsPerPage,
    };
    await MMCMembersStore.getPaginatedMMCMembers(queryParams);
    this.tableLoading = false;

    this.pagination = {
      page: this.paginatedMmcMembers.current_page,
      rowsPerPage: this.paginatedMmcMembers.page_size,
      rowsNumber: this.paginatedMmcMembers.total_objects,
      sortBy: sortBy,
      descending: descending,
    };
  };

  filterValue = () => {
    this.onRequest({
      pagination: MMCMembersStore.QTablePaginationData,
      filter: this.searchText,
    });
  };

  setDeleteId(id: number) {
    this.openDeleteDialog = true;
    this.deleteId = id;
  }

  async deleteMmcById() {
    await MMCMembersStore.deleteMmcById({ id: this.deleteId })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully deleted",
          color: "info",
        });
        this.filterValue();
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }

  column = [
    {
      name: "date_created",
      align: "left",
      label: "Date",
      field: "date_created",
      sortable: true,
    },
    {
      name: "name",
      align: "center",
      label: "Name",
      field: "name",
      sortable: true,
    },
    {
      name: "designation",
      align: "center",
      label: "Designation",
      field: "designation",
      sortable: true,
    },
    {
      name: "phone_number",
      align: "center",
      label: "Phone Number",
      field: "phone_number",
      sortable: true,
    },
    {
      name: "image",
      align: "center",
      label: "Photo",
      field: "image",
      sortable: true,
    },
    {
      name: "action",
      label: "Action",
      field: "",
      align: "center",
    },
  ];
}
