
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { MarketStore, TraderStore, TransportStore } from "@/store/modules";
import { QTablePagination } from "@/store/model/page";

@Component({
  components: { ContentHeader, AppFooter },
})
export default class TraderScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  searchText = "";
  openDeleteDialog = false;
  deleteId = 0;

  currentPage = 1;
  tableLoading = false;
  totalPages = 0;

  created() {
    this.tableLoading = true;
    this.filterValue();
    this.tableLoading = false;
  }

  get paginatedTraders() {
    return TraderStore.PaginatedTraderList;
  }

  get pagination() {
    return TraderStore.QTablePaginationData;
  }

  set pagination(data: QTablePagination) {
    TraderStore.setTradersPagination(data);
  }

  onRequest = async (props: any) => {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const filter = props.filter;

    this.tableLoading = true;

    const ordering = `${descending ? "-" : ""}${sortBy}`;

    const queryParams = {
      page: page,
      search: filter,
      market: MarketStore.MARKETPLACE_ID,
      ordering: ordering,
      pageSize: rowsPerPage,
    };
    await TraderStore.getPaginatedTraderList(queryParams);
    this.tableLoading = false;

    this.pagination = {
      page: this.paginatedTraders.current_page,
      rowsPerPage: this.paginatedTraders.page_size,
      rowsNumber: this.paginatedTraders.total_objects,
      sortBy: sortBy,
      descending: descending,
    };
  };

  filterValue = () => {
    this.onRequest({
      pagination: TraderStore.QTablePaginationData,
      filter: this.searchText,
    });
  };

  setDeleteId(id: number) {
    this.openDeleteDialog = true;
    this.deleteId = id;
  }

  async deleteTraderById() {
    await TraderStore.deleteTradersById({ id: this.deleteId })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully deleted",
          color: "info",
        });
        this.filterValue();
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }

  column = [
    // {
    //   name: "date_created",
    //   align: "left",
    //   label: "Date",
    //   field: "date_created",
    //   sortable: true,
    // },

    {
      name: "focal_person",
      required: true,
      label: "Focal Person",
      align: "left",
      field: (row: any) => row.focal_person,
      sortable: true,
    },

    {
      name: "phone_number",
      required: true,
      label: "Phone Number",
      align: "left",
      field: (row: any) => row.phone_number,
      sortable: true,
    },

    {
      name: "company_name",
      required: true,
      label: "Company Name",
      align: "left",
      field: (row: any) => row.company_name,
      sortable: true,
    },

    {
      name: "preffered_products",
      required: true,
      label: this.$t("preffered_products"),
      align: "left",
      field: (row: any) => {
        let products: string[] = [];
        row.preffered_products.forEach((x: string) => {
          products.push(x);
        });
        products.join(",");
        return products;
      },
      format: (val: any) => `${val}`,
      sortable: true,
    },

    {
      name: "company_logo",
      align: "center",
      label: "Company Logo",
      field: "company_logo",
      sortable: true,
    },

    {
      name: "action",
      label: "Action",
      field: "",
      align: "center",
    },
  ];
}
