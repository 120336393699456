
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import Analytics from "@/components/UIComponents/Analytics.vue";
import { roleChecker } from "@/utils/jwt";

@Component({
  components: { ContentHeader, AppFooter, Analytics },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class DashboardScreen extends Vue {
  headerBackgroundUrl = "about.jpg";

  roleChecker(roles: string[]) {
    return roleChecker(roles);
  }

  navigateToAgent() {
    this.$router.push({
      name: "Agent",
    });
  }

  navigateToHome() {
    this.$router.push({
      name: "Home",
    });
  }
  navigateToTransport() {
    this.$router.push({
      name: "Transport",
    });
  }
  navigateToNews() {
    this.$router.push({
      name: "News",
    });
  }
  navigateToAnalytics() {
    this.$router.push({
      name: "Analytics",
    });
  }
  navigateToTrader() {
    this.$router.push({
      name: "Trader",
    });
  }
  navigateToReports() {
    this.$router.push({
      name: "Reports",
    });
  }
  navigateToMMCmembers() {
    this.$router.push({
      name: "MmcMemberList",
    });
  }
}
