
import { Line, mixins } from "vue-chartjs";
import colors from 'quasar/src/utils/colors.js';;
import { MarketStore, NewsStore } from "@/store/modules";
import { Prop, Watch } from "vue-property-decorator";
import { Vue, Component } from "vue-property-decorator";

const { reactiveProp } = mixins;

@Component({
  components: {},
  // props:{
  //   chartData: Object
  // },
  extends: Line,
  mixins: [reactiveProp],
})
export default class LineChart extends Vue {
  public renderChart!: (chartData: any, options: any) => void;

  @Prop()
  chartData: any;

  created() {
    console.log(colors.getPaletteColor("primary"));
  }

  async fetchLineChartData() {
    let filter: any = {};
    filter["market_id"] = MarketStore.MARKETPLACE_ID;
    filter["typeId"] = "price_product_line";

    await MarketStore.getMarketLineChart({
      filters: filter,
    });
  }

  // get chartDataValue(){
  //   return (this as any).chartData
  // }

  // set chartDataValue(updatedData: any){
  //   (this as any).chartData = updatedData
  // }

  // @Watch('chartData')
  // onPropertyChanged(){
  //   this.chartDataValue = (this as any).chartData
  // }

  mounted() {
    this.renderChart(this.chartData, {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Product Price Variation Over Time",
      },
    });
  }
}
