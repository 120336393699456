import { render, staticRenderFns } from "./CreateTransportScreen.vue?vue&type=template&id=4767b0a0"
import script from "./CreateTransportScreen.vue?vue&type=script&lang=ts"
export * from "./CreateTransportScreen.vue?vue&type=script&lang=ts"
import style0 from "./CreateTransportScreen.vue?vue&type=style&index=0&id=4767b0a0&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QList from 'quasar/src/components/item/QList.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QForm,QInput,QSelect,QItem,QItemSection,QSkeleton,QList,QItemLabel});qInstall(component, 'directives', {Ripple});
