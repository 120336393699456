
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { CreateTransport, Transport } from "@/store/model/transport";
import {
  AdministrativeAreaStore,
  MarketStore,
  TransportStore,
} from "@/store/modules";
import router from "@/router";
import { Category } from "@/store/model/market";
import { filter } from "vue/types/umd";
import Loading from 'quasar/src/plugins/Loading.js';;
@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class CreateTransportScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  navigationTitle = "आगमन सिर्जना गर्नुहोस्";

  createTransportModel = [];
  createButtonLoading = false;

  tempTransportModel = {
    market: "",
    commodity: "",
    category: "",
    quantity: "",
    country: "",
    province: "",
    district: "",
  };

  countries = [
    {
      name: "China",
      id: "CH",
    },

    {
      name: "India",
      id: "IN",
    },

    {
      name: "Nepal",
      id: "NP",
    },
  ];

  totalAddQuantities = 1;
  commodityCategory = null;
  provinceDistrictVisible = false;
  districtSelectDisabled = true;

  sectionLoading = false;
  submitButtonText = "Add";

  // categoryOptions = [] as any
  filteredCategoryOptions = [] as any;
  commodity = null;

  disableProductSelect = false;
  showBulkCreate = true;
  disableCategorySelect=false
  disableCountrySelect = false

  async created() {
    const route_params = this.$route.params;

    if (route_params.id) {
      this.navigationTitle = "आगमन सम्पादन गर्नुहोस्";
      this.submitButtonText = "Edit";
      this.showBulkCreate = false;
      this.sectionLoading = true;
      this.disableCategorySelect = true
      this.disableCountrySelect = true

      await this.fetchTransportByID(route_params);
      this.sectionLoading = false;
    }

    await MarketStore.getProductCategoryList();
    await AdministrativeAreaStore.getProvinceList();

    this.productCategories;
  }

  async fetchTransportByID(route_params: any) {
    const transportId = route_params.id;

    this.tempTransportModel = await TransportStore.getTransportById({
      transportId: transportId,
    });

    for (let country of this.countries) {
      if (String(country.id) == String(this.tempTransportModel.country)) {
        (this as any).tempTransportModel.country = country;
      }
    }

    await this.fetchCommodityCategoryByID();
  }

  async fetchCommodityCategoryByID() {
    if(!this.$route.params.id){
      this.disableProductSelect = false;
    }
    const category_id = (this as any).tempTransportModel.product_category;
    this.tempTransportModel.category = await MarketStore.getProductCategoryByID(
      category_id
    );

    this.fetchCommodityByID();
  }

  async fetchCommodityByID() {
    const product_id = (this as any).tempTransportModel.product;

    this.filterCommodity();

    this.tempTransportModel.commodity = await MarketStore.getProductByID(
      product_id
    );
  }

  async fetchDistrictByProvinceId() {
    let filters: any = {};

    filters["province"] = (this as any).tempTransportModel.province.sid;

    await AdministrativeAreaStore.getDistrictByProvinceId({
      filters: filters,
    });

    this.districtSelectDisabled = false;
  }

  navigateToTransportScreen() {
    this.$router.push({
      name: "Transport",
    });
  }

  filterCommodityCategory(val: any, update: any) {
    if (val == "") {
      update(() => {
        this.filteredCategoryOptions = this.productCategories;
      });
      return;
    }

    update(() => {
      const needle = val.toLowerCase();
      const categoryArray = Object.values(this.productCategories);
      console.log("array -> ", categoryArray);

      for (let item in this.productCategories) {
        console.log(item, (this as any).productCategories[item]);
      }
    });
  }

  async filterCommodity() {
    if(!this.$route.params.id){
      this.disableProductSelect = false;
    }

    let filters: any = {};

    if ((this as any).tempTransportModel.category.name) {
      this.tempTransportModel.commodity = "";
      filters["category__name"] = String(
        (this as any).tempTransportModel.category.name
      );
    }

    await MarketStore.getProductList({
      filters: filters,
    });
  }

  get filteredProductList() {
    return MarketStore.ProductList;
  }

  get provinceList() {
    return AdministrativeAreaStore.ProvinceList;
  }

  get districtList() {
    return AdministrativeAreaStore.DistrictList;
  }

  async createTransport() {
    this.createButtonLoading = true as boolean;

    (this as any).createTransportModel.market = MarketStore.MARKETPLACE_ID;

    if (!this.$route.params.id) {
      await TransportStore.createTransport({
        transport: this.createTransportModel,
      })
        .then(() => {
          this.$q.notify({
            message: "Created Sucessfully!",
            color: "positive",
          });
          this.navigateToTransportScreen();
        })
        .catch(() => {
          this.$q.notify("An error occurred while performing this action.");
        });
    } else {
      // Edit Transport here...

      // sending just id of the country object in patch
      const tempCountryObj: any = this.tempTransportModel.country;
      this.tempTransportModel.country = tempCountryObj.id;

      await TransportStore.updateTransport({
        transport: this.tempTransportModel,
        transportId: this.$route.params.id,
      })
        .then(() => {
          this.$q.notify({
            message: "Edited Sucessfully!",
            color: "positive",
          });
          this.navigateToTransportScreen();
        })
        .catch(() => {
          this.$q.notify("An error occurred while performing this action.");
        });
    }

    this.createButtonLoading = false;
  }

  appendTransportList() {
    // appending the form values to list for bulk create
    let market_id = MarketStore.MARKETPLACE_ID;
    this.tempTransportModel.market = MarketStore.MARKETPLACE_ID as string;
    (this as any).createTransportModel.push(this.tempTransportModel);

    (this.tempTransportModel = {
      market: "",
      commodity: "",
      category: "",
      quantity: "",
      country: "",
      province: "",
      district: "",
    }),
      (this as any).$refs.transportForm.resetValidation();
  }

  toggleProvinceDistrictVisible() {
    if ((this as any).tempTransportModel.country.id == "NP") {
      this.provinceDistrictVisible = true;
    } else {
      this.provinceDistrictVisible = false;
    }
  }

  removeItemFromList(index: any) {
    this.createTransportModel.splice(index, 1);
  }

  get productCategories() {
    const res = MarketStore.CategoryList;
    console.log("res ->", res);
    return res;
  }
}
