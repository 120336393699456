
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import {
  MarketStore,
  NewsStore,
  TraderStore,
  TransportStore,
} from "@/store/modules";
import colors from 'quasar/src/utils/colors.js';;
import ref from "vue";
import { Trader } from "@/store/model/trader";

@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class TraderScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  searchText = null;
  openDeleteDialog = false;
  transportId = "";

  currentPage = 1;
  contentLoading = false;
  totalPages = 0;

  traderId = "";

  get singleTrader() {
    return TraderStore.SingleTrader;
  }

  async created() {
    this.contentLoading = true;
    this.traderId = this.$route.params.id;

    if (this.$route.params) {
      await TraderStore.getTraderByID(this.traderId);
    }
    this.contentLoading = false;
  }

  navigateToEditTrader(id: any) {
    this.$router.push({
      name: "EditTrader",
      params: {
        id,
      },
    });
  }

  navigateToTrader() {
    if (this.$route.query.fromContact) {
      this.$router.push({
        name: "Contact",
      });
    } else {
      this.$router.push({
        name: "Trader",
      });
    }
  }

  // toggleDeleteDialog(transportId: any) {
  //     let state = this.openDeleteDialog;
  //     this.openDeleteDialog = !state;
  //     this.transportId = transportId;
  // }
}
