
import { Component, Vue } from "vue-property-decorator";
import { MarketStore } from "@/store/modules";
import { eventHub } from "@/utils/eventHub";
import { Base, getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import { Product, ProductPrice } from "@/store/model/market";

import date from 'quasar/src/utils/date.js';;

import { BSToAD } from "bikram-sambat-js";

import NepaliDatePicker from "@/components/UIComponents/DatePicker/NepaliDatePicker.vue";

import exportFile from 'quasar/src/utils/export-file.js';;
import { nepaliUnicodeNumber } from "@/utils/date";
import { QTablePagination } from "@/store/model/page";

@Component({
  components: { ContentHeader, NepaliDatePicker },

  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class PriceReports extends Vue {
  headerBackgroundUrl = "about.jpg";
  ProductListDownlading = "";
  isHomeScreen = true;
  tableLoading = false;
  asOf = "";
  currentDate = "";
  startDate = "";
  endDate = "";
  currentPage: any = "";
  totalPages: any = "";
  commodityCategoryModel = "";

  get columns() {
    return [
      {
        name: "product_name",
        required: true,
        label: `${this.$i18n.t("commodity")}`,
        align: "left",
        field: (row: ProductPrice): string => {
          return this.$i18n.locale == "ne"
            ? row.product_name_ne
              ? row.product_name_ne
              : row.product_name
            : row.product_name;
        },
      },

      {
        name: "wholesale_avg",
        align: "right",
        label: `${this.$i18n.t("avgPrice")} (${this.$i18n.t("Rs.")})`,
        field: "wholesale_avg",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
      {
        name: "wholesale_min",
        align: "right",
        label: `${this.$i18n.t("minPrice")} (${this.$i18n.t("Rs.")})`,

        field: "wholesale_min",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
      {
        name: "wholesale_max",
        align: "right",
        label: `${this.$i18n.t("maxPrice")} (${this.$i18n.t("Rs.")})`,
        field: "wholesale_max",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
      {
        name: "date",
        required: true,
        label: this.$i18n.t("date"),
        align: "left",
        field: (row: any) => nepaliUnicodeNumber(row.nep_date),
      },
      // {
      //   name: "Action",
      //   required: true,
      //   label: "Action ",
      //   align: "left",
      //   field: (row: any) => row.product_name,
      //   format: (val: any) => `${val}`,
      // },
    ];
  }
  // pagination = {
  //   rowsPerPage: 10,
  //   sortBy: "product_name",
  //   descending: false,
  //   page: 1,
  // };

  get pagination() {
    return MarketStore.ReportTablePagination;
  }

  set pagination(data: QTablePagination) {
    MarketStore.setReportPagination(data);
  }

  get commodityCategories() {
    const categoryList = JSON.parse(JSON.stringify(MarketStore.CategoryList));
    categoryList.unshift({
      sid: "",
      name: "All",
      name_ne: "सबै",
    });
    return categoryList;
  }

  get marketDates() {
    return MarketStore.MarketDates;
  }

  loadReportData() {
    this.pagination.page = 1;
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }

  async created() {
    await MarketStore.getMarketPriceDates();
    if (this.marketDates.length > 0) {
      this.currentDate = this.marketDates[0];
      this.startDate = this.marketDates[0];
      this.endDate = this.marketDates[0];
    }
    this.loadReportData();
    await MarketStore.getProductCategoryList();

    // assigning button visibility if component appears out of homescreen
    if (this.$route.name != "Home") {
      this.isHomeScreen = false;
    } else {
      this.isHomeScreen = true;
    }

    eventHub.$on(
      "emitPaginatedProductPriceList",
      this.fetchPaginatedProductPriceList
    );
  }

  //eslint-disable-next-line
  async onRequest(props: any) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const filter = props.filter;
    this.tableLoading = true;

    const ordering = `${descending ? "-" : ""}${sortBy}`;

    const queryParams = {
      page: page,
      search: filter,
      ordering: ordering,
      pageSize: rowsPerPage,
      commodity: this.commodityCategoryModel,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    await MarketStore.getPaginatedProductPriceDateRangeList(queryParams);
    this.tableLoading = false;

    this.pagination = {
      page: this.paginatedProductPriceList.current_page,
      rowsPerPage: this.paginatedProductPriceList.page_size,
      rowsNumber: this.paginatedProductPriceList.total_objects,
      sortBy: sortBy,
      descending: descending,
    };
    console.log(this.pagination);
  }

  async fetchPaginatedProductPriceList(
    filteredData: any = null,
    currentPage: any = null
  ) {
    if (this.currentPage == "") {
      this.currentPage = 1;
    }

    let filters: any = {};
    let searchString = "";
    this.tableLoading = true;

    if (filteredData) {
      if (filteredData.product) {
        filters["product"] = filteredData.product.sid;
      }
      // if(filteredData.product_category){
      //   filters['product_category'] = filteredData.product_category.sid
      // }
      if (filteredData.date) {
        filters["date"] = filteredData.date;
      }
      if (filteredData.dateNepali) {
        filters["date_nepali"] = filteredData.dateNepali;
      }
      if (filteredData.search) {
        searchString = filteredData.search;
      }
    }

    if (this.commodityCategoryModel != "") {
      filters["product__category"] = this.commodityCategoryModel;
    }
    console.log("Filters-> ", filters, searchString);

    const marketId = MarketStore.MARKETPLACE_ID;

    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    filters["market"] = marketId;
    // filters['date'] = date

    await MarketStore.getPaginatedProductPriceDateRangeList({
      startDate: this.startDate,
      endDate: this.endDate,
      filters: filters,
      page: this.currentPage,
      searchString: searchString,
    });

    this.paginatedProductPriceList;
    if (
      this.paginatedProductPriceList.results.length != 0 &&
      (this as any).paginatedProductPriceList.results[0].date == date
    ) {
      this.asOf = (this as any).paginatedProductPriceList.results[0].date;
      console.log(this.asOf);
    } else if ((this as any).paginatedProductPriceList.results.length != 0) {
      this.asOf = (this as any).paginatedProductPriceList.results[0].date;
      console.log(this.asOf);
    }

    this.tableLoading = false;

    this.totalPages = this.paginatedProductPriceList.total_pages;
    this.currentPage = this.paginatedProductPriceList.page;

    console.log(this.tableLoading);
  }

  get paginatedProductPriceList() {
    console.log(
      "product price, ",
      MarketStore.PaginatedProductPriceList.results
    );
    return MarketStore.PaginatedProductPriceList;
  }

  // get paginatedProductList() {
  //   const res = MarketStore.PaginatedProductList;
  //   console.log(res);
  //   return res;
  // }

  async downloadProductList() {
    // const link = document.createElement("a");
    const url = `${Action.ExportProductPriceDateRangeList.replace(
      "<marketId>",
      MarketStore.MARKETPLACE_ID
    )
      .replace("<nepStartDate>", this.startDate)
      .replace("<nepEndDate>", this.endDate)}`;

    let query = "";

    if (this.commodityCategoryModel != "") {
      query += `?product__category=${this.commodityCategoryModel}`;
    }
    const fileData = await getApi(url, query, { responseType: "blob" });
    exportFile(
      "Product Prices.xlsx",
      fileData,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  }

  navigateToPriceMonitorScreen() {
    this.$router.push({
      name: "PriceMonitor",
    });
  }
  allowedStartDate(dt: any) {
    const endDate = new Date(BSToAD(this.endDate));
    const diff = date.getDateDiff(dt.timestamp, endDate, "days");
    return diff <= 0;
  }
  allowedEndDate(dt: any) {
    const startDate = new Date(BSToAD(this.startDate));
    const diff = date.getDateDiff(startDate, dt.timestamp, "days");
    return diff <= 0;
  }
}
