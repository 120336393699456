
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { Transport } from "@/store/model/transport";
import { MarketStore, NewsStore, TransportStore } from "@/store/modules";
import router from "@/router";
import { Category } from "@/store/model/market";
import { filter } from "vue/types/umd";
import { News } from "@/store/model/news";

@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class CreateNewsScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  navigationTitle = "Create News";
  news: News = {} as News;

  commodityCategory = null;
  sectionLoading = false;
  submitButtonText = "Create";
  // categoryOptions = [] as any
  filteredCategoryOptions = [] as any;
  commodity = null;
  disableProductSelect = true;
  imageModel: any = "";
  routeId: any = null;
  openDeleteDialog = false;
  isUpdateBtnLoading = false;

  fontOptions = {
    arial: "Arial",
    arial_black: "Arial Black",
    comic_sans: "Comic Sans MS",
    courier_new: "Courier New",
    impact: "Impact",
    lucida_grande: "Lucida Grande",
    times_new_roman: "Times New Roman",
    verdana: "Verdana",
  };

  toolbarOptions = [
    [
      {
        label: this.$q.lang.editor.align,
        icon: this.$q.iconSet.editor.align,
        fixedLabel: true,
        list: "only-icons",
        options: ["left", "center", "right", "justify"],
      },
      {
        label: this.$q.lang.editor.align,
        icon: this.$q.iconSet.editor.align,
        fixedLabel: true,
        options: ["left", "center", "right", "justify"],
      },
    ],
    ["bold", "italic", "strike", "underline", "subscript", "superscript"],
    ["token", "hr", "link", "custom_btn"],
    ["print", "fullscreen"],
    [
      {
        label: this.$q.lang.editor.formatting,
        icon: this.$q.iconSet.editor.formatting,
        list: "no-icons",
        options: ["p", "h1", "h2", "h3", "h4", "h5", "h6", "code"],
      },
      {
        label: this.$q.lang.editor.fontSize,
        icon: this.$q.iconSet.editor.fontSize,
        fixedLabel: true,
        fixedIcon: true,
        list: "no-icons",
        options: [
          "size-1",
          "size-2",
          "size-3",
          "size-4",
          "size-5",
          "size-6",
          "size-7",
        ],
      },
      {
        label: this.$q.lang.editor.defaultFont,
        icon: this.$q.iconSet.editor.font,
        fixedIcon: true,
        list: "no-icons",
        options: [
          "default_font",
          "arial",
          "arial_black",
          "comic_sans",
          "courier_new",
          "impact",
          "lucida_grande",
          "times_new_roman",
          "verdana",
        ],
      },
      "removeFormat",
    ],
    ["quote", "unordered", "ordered", "outdent", "indent"],

    ["undo", "redo"],
    ["viewsource"],
  ];

  async created() {
    const route_params = this.$route.params;
    this.news.desc = "Description...";
    this.routeId = route_params.id;

    if (route_params.id) {
      this.navigationTitle = "Edit News";
      this.submitButtonText = "Edit";
      this.sectionLoading = true;

      await this.fetchNewsByID(route_params);

      this.sectionLoading = false;
    }

    await MarketStore.getProductCategoryList();
  }

  async fetchNewsByID(params: any) {
    const newsId = params.id;

    this.news = await NewsStore.getNewsByID({
      newsId: newsId,
    });
  }

  navigateToNewsScreen() {
    this.$router.push({
      name: "News",
    });
  }

  async createNews() {
    const newsFormData = new FormData();

    this.news.market = MarketStore.MARKETPLACE_ID;

    newsFormData.append("title", this.news.title);
    newsFormData.append("desc", this.news.desc);
    newsFormData.append("market", this.news.market);

    if (!this.routeId) {
      newsFormData.append("image", this.news.image);
      await NewsStore.createNews({ news: newsFormData })
        .then(() => {
          this.$q.notify({
            message: "News Created Sucessfully!",
            color: "positive",
          });
          this.navigateToNewsScreen();
        })
        .catch(() => {
          this.$q.notify("An error occurred while performing this action.");
        });
    } else {
      // newsFormData.append("image", this.news.image);
      await NewsStore.patchNews({
        newsId: this.routeId,
        news: newsFormData,
      })
        .then(() => {
          this.$q.notify({
            message: "News Created Sucessfully!",
            color: "positive",
          });
          this.navigateToNewsScreen();
        })
        .catch(() => {
          this.$q.notify("An error occurred while performing this action.");
        });
    }
  }

  getFile() {
    (this as any).$refs.myFileInput.$el.click();
  }

  async uploadNewsImage() {
    this.isUpdateBtnLoading = true;
    const newsFormData = new FormData();

    for (let item of this.imageModel) {
      newsFormData.append("image", item);
    }

    this.news = await NewsStore.patchNewsImage({
      newsId: this.routeId,
      news: newsFormData,
    });
    this.isUpdateBtnLoading = false;
  }

  async removeNewsImage() {
    const newsFormData = new FormData();
    newsFormData.append("image", new File([], ""));

    this.news = await NewsStore.removeNewsImageByID({
      newsId: this.routeId,
      news: newsFormData,
    });
  }
}
