
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import ContactPerson from "@/components/UIComponents/ContactPerson.vue";
import ContactEmployee from "@/components/UIComponents/ContactEmployee.vue";
import { MarketStore, TraderStore } from "@/store/modules";

@Component({
  components: { ContentHeader, AppFooter, ContactPerson, ContactEmployee },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class ContactScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  tableLoading = false;

  async created() {
    this.tableLoading = true;
    await this.fetchPaginatedTraderList();
    this.tableLoading = false;
  }

  async fetchPaginatedTraderList() {
    let filters: any = {};
    filters["market"] = MarketStore.MARKETPLACE_ID;

    await TraderStore.getPaginatedTraderList({
      filters: filters,
    });
  }

  get paginatedTraderList() {
    return TraderStore.PaginatedTraderList;
  }

  navigateToTraderDetailScreen(traderId: any) {
    this.$router.push({
      name: "TraderDetail",
      params: {
        id: traderId,
      },
      query: {
        fromContact: "True",
      },
    });
  }

  dataColumn = [
    // {
    //   name: "date_created",
    //   align: "left",
    //   label: this.$t('dateCreated'),
    //   field: "date_created",
    //   sortable: true,
    // },

    {
      name: "focal_person",
      required: true,
      label: this.$t("focal_person"),
      align: "left",
      field: (row: any) => row.focal_person,
      sortable: true,
    },

    {
      name: "phone_number",
      required: true,
      label: this.$t("phone_number"),
      align: "left",
      field: (row: any) => row.phone_number,
      sortable: true,
    },

    {
      name: "company_name",
      required: true,
      label: this.$t("company_name"),
      align: "left",
      field: (row: any) => row.company_name,
      sortable: true,
    },

    {
      name: "preffered_categories",
      required: true,
      label: this.$t("preffered_products"),
      align: "left",
      field: (row: any) => row.preffered_products,
      sortable: true,
    },
  ];
}
