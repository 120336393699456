
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { MarketStore, TransportStore } from "@/store/modules";
import colors from 'quasar/src/utils/colors.js';;
import ref from "vue";

@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class TransportScreen extends Vue {
  marketId = 0;
  headerBackgroundUrl = "about.jpg";
  searchText = null;
  openDeleteDialog = false;
  transportId = "";

  currentPage = 1;
  tableLoading = false;
  totalPages = 0;

  pagination = {
    rowsPerPage: 0,
  };
  async created() {
    this.tableLoading = true;
    this.marketId = MarketStore.MARKETPLACE_ID;
    await this.fetchPaginatedTransportList();
    this.tableLoading = false;
    console.log(colors.getPaletteColor("primary"));
  }

  async fetchPaginatedTransportList() {
    let filters: any = {};
    filters["market"] = MarketStore.MARKETPLACE_ID;

    if (this.searchText) {
      console.log("searchtext");

      await TransportStore.getPaginatedTransportList({
        filters: filters,
        page: 1,
        searchString: this.searchText,
      });
      this.currentPage = 1;
    } else {
      await TransportStore.getPaginatedTransportList({
        filters: filters,
        page: this.currentPage,
      });
    }

    this.paginatedTransportList;
    this.totalPages = this.paginatedTransportList.total_pages;
    this.currentPage = this.paginatedTransportList.page;
  }

  get paginatedTransportList() {
    console.log(TransportStore.PaginatedTransportList);
    return TransportStore.PaginatedTransportList;
  }

  dataColumn = [
    {
      name: "index",
      label: "#",
      field: "index",
    },

    {
      name: "date_created",
      align: "left",
      label: "मिति",
      field: "date_created",
      sortable: true,
    },

    {
      name: "product",
      align: "left",
      label: "बस्तू ",
      field: "product_name",
      sortable: true,
    },

    {
      name: "country_name",
      label: "देश",
      align: "left",
      field: "country_name",
      sortable: true,
      sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
    },

    {
      name: "add_address",
      label: "अतिरिक्त ठेगाना",
      align: "left",
      field: "add_address",
      sortable: true,
      sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
    },

    {
      name: "quantity",
      align: "left",
      label: "मात्रा (मेट. ट.)",
      field: "quantity",
      sortable: true,
    },

    { name: "id", align: "left", label: "कार्य", field: "id", sortable: true },
  ];

  navigateToCreateTransport() {
    this.$router.push({
      name: "CreateTransport",
    });
  }

  navigateToEditTransport(id: any) {
    this.$router.push({
      name: "EditTransport",
      params: {
        id,
      },
    });
  }

  navigateToDashboard() {
    this.$router.push({
      name: "Dashboard",
    });
  }

  toggleDeleteDialog(transportId: any) {
    let state = this.openDeleteDialog;
    this.openDeleteDialog = !state;
    this.transportId = transportId;
  }

  async deleteTransportById() {
    await TransportStore.deleteTransportById({
      transportId: this.transportId,
    })
      .then(() => {
        this.$q.notify({
          message: "Sucessfully deleted",
          color: "info",
        });
        this.fetchPaginatedTransportList();
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }
}
