var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alignLeft"},[_c('content-header',{attrs:{"title":"ड्यासबोअर्ड"}}),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"q-pb-lg"},[_c('q-btn',{attrs:{"color":"white"},on:{"click":function($event){return _vm.navigateToHome()}}},[_c('i',{staticClass:"fas fa-arrow-left",staticStyle:{"color":"black"}})]),_c('strong',{staticClass:"q-pl-md text-h4"},[_vm._v(" ड्यासबोअर्ड ")])],1),_c('div',{staticClass:"row"},[(_vm.roleChecker(['Entrier', 'AgentAndEntrier', 'Superuser']))?_c('div',{staticClass:"col-lg-3 col-md-3 col-12 q-pa-sm"},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToTransport()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-truck iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("आगमन")])])])],1)],1)]):_vm._e(),(_vm.roleChecker(['Agent', 'AgentAndEntrier', 'Superuser']))?_c('div',{class:_vm.$q.screen.lt.md
              ? `col-lg-3 col-md-3 col-12 q-pa-sm`
              : `col-lg-3 col-md-3 col-12 q-pa-sm`},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToReports()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-file iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("Reports")])])])],1)],1)]):_vm._e(),(_vm.roleChecker(['Agent', 'AgentAndEntrier', 'Superuser']))?_c('div',{class:_vm.$q.screen.lt.md
              ? `col-lg-3 col-md-3 col-12 q-pa-sm`
              : `col-lg-3 col-md-3 col-12 q-pa-sm`},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToNews()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-newspaper iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("News")])])])],1)],1)]):_vm._e(),(
            _vm.roleChecker(['Agent', 'Entrier', 'Superuser', 'AgentAndEntrier'])
          )?_c('div',{class:_vm.$q.screen.lt.md
              ? `col-lg-3 col-md-3 col-12 q-pa-sm`
              : `col-lg-3 col-md-3 col-12 q-pa-sm`},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToTrader()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-users iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("Trader")])])])],1)],1)]):_vm._e(),(
            _vm.roleChecker(['Agent', 'Entrier', 'Superuser', 'AgentAndEntrier'])
          )?_c('div',{class:_vm.$q.screen.lt.md
              ? `col-lg-3 col-md-3 col-12 q-pa-sm`
              : `col-lg-3 col-md-3 col-12 q-pa-sm`},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToMMCmembers()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-users iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("MMC Members")])])])],1)],1)]):_vm._e(),(_vm.roleChecker(['Superuser']))?_c('div',{class:_vm.$q.screen.lt.md
              ? `col-lg-3 col-md-3 col-12 q-pa-sm`
              : `col-lg-3 col-md-3 col-12 q-pa-sm`},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToAgent()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-user-tie iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("Agents")])])])],1)],1)]):_vm._e(),(_vm.roleChecker(['Agent', 'AgentAndEntrier', 'Superuser']))?_c('div',{class:_vm.$q.screen.lt.md
              ? `col-lg-3 col-md-3 col-12 q-pa-sm`
              : `col-lg-3 col-md-3 col-12 q-pa-sm`},[_c('div',{staticClass:"custom-mb-20"},[_c('q-card',{staticClass:"dash_card",on:{"click":function($event){return _vm.navigateToAnalytics()}}},[_c('q-card-section',[_c('div',{staticClass:"q-pa-md"},[_c('i',{staticClass:"fas fa-chart-bar iconSize"})]),_c('div',{staticClass:"q-pa-md"},[_c('strong',[_vm._v("Analytics")])])])],1)],1)]):_vm._e()])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }