
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { MarketStore } from "@/store/modules";
import colors from 'quasar/src/utils/colors.js';;
import AnalysisChart from "@/components/UIComponents/AnalysisChart.vue";
import LineChart from "@/components/UIComponents/charts/LineChart.vue";
import PieChart from "@/components/UIComponents/charts/PieChart.vue";
import HighChartsChart from "@/components/UIComponents/charts/HighChartReflow.vue";
import GenericChart from "@/components/UIComponents/charts/GenericChart.vue";

@Component({
  components: {
    ContentHeader,
    AppFooter,
    AnalysisChart,
    LineChart,
    PieChart,
    HighChartsChart,
    GenericChart,
  },
})
export default class AnalyticsScreen extends Vue {
  // headerBackgroundUrl = "about.jpg";

  chartData = {};
  HighChartData = {};
  dataLoaded = false;
  categoryModel = "";
  commodityModal = "";
  disableCommoditySelect = true;
  showHighStock = false

  async created() {
    // this.fetchLineChartData();
    await MarketStore.getProductCategoryList();

    // assigning initial data to the select options
    this.categoryModel = (this as any).productCategories[0];
    this.disableCommoditySelect = false;

    await this.fetchCommodityByCategoryName();

    this.commodityModal = (this as any).commodities[0];
    console.log("commodity", this.commodityModal);

    await this.fetchLineChartData();

    await this.fetchHighChartLineData();
    await this.fetchTransportVolume();
  }

  get productCategories() {
    return MarketStore.CategoryList;
  }

  get commodities() {
    return MarketStore.ProductList;
  }

  // get chartDataValue(){
  //   return this.chartData
  // }

  // set chartDataValue(lineChartData: any){
  //   this.configureDataToChart(lineChartData)
  // }

  toggleDisableCommoditySelect() {
    this.commodityModal = "";
    this.disableCommoditySelect = true;
  }

  async fetchLineChartData() {
    let filter: any = {};
    filter["market_id"] = MarketStore.MARKETPLACE_ID;
    filter["product_id"] = (this as any).commodityModal.sid;
    filter["typeId"] = "price_product_line";

    await MarketStore.getMarketLineChart({
      filters: filter,
    });

    this.lineChartData;
    this.fetchHighChartLineData();
    this.fetchTransportVolume();
    this.configureDataToChart();
    this.showHighStock = true
  }

  async fetchHighChartLineData() {
    let filter: any = {};
    filter["market_id"] = MarketStore.MARKETPLACE_ID;
    filter["product_id"] = (this as any).commodityModal.sid;
    // filter["typeId"] = "price_product_line";

    await MarketStore.getMarketTimeSeriesLineHighChart({
      filters: filter,
    });

    this.highChartLineData;
    this.configureDataToChart();
  }

  async fetchTransportVolume() {
    let filter: any = {};
    filter["market_id"] = MarketStore.MARKETPLACE_ID;
    filter["product_id"] = (this as any).commodityModal.sid;
    // filter["typeId"] = "price_product_line";

    await MarketStore.getTransportChartData({
      filters: filter,
    });

    this.TransportVolumeData;
  }

  configureDataToChart() {
    this.dataLoaded = false;
    this.chartData = {
      labels: this.lineChartData.label,
      datasets: [
        {
          label: this.lineChartData.data[0].label,
          data: (this as any).lineChartData.data[0].data,
          backgroundColor: "transparent",
          borderColor: "rgba(220,20,60,1)",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
        {
          label: this.lineChartData.data[1].label,
          data: (this as any).lineChartData.data[1].data,
          backgroundColor: "transparent",
          borderColor: "rgba(0,0,128, 1)",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
        {
          label: this.lineChartData.data[2].label,
          data: (this as any).lineChartData.data[2].data,
          backgroundColor: "transparent",
          borderColor: "rgba(0,255,255, 1)",
          pointBackgroundColor: "rgba(171, 71, 188, 1)",
        },
      ],
    };

    this.dataLoaded = true;
  }

  get lineChartData() {
    console.log("This is working", MarketStore.MarketLineChart);
    return MarketStore.MarketLineChart;
  }
  get highChartLineData() {
    return MarketStore.TimeSeriesLineChart;
  }
  get TransportVolumeData() {
    return MarketStore.TransportLineChartData;
  }
  async fetchCommodityByCategoryName() {
    const categoryName = (this as any).categoryModel.name;

    const filters: any = {};
    filters["category__name"] = categoryName;

    await MarketStore.getProductList({
      filters: filters,
    });

    this.commodityModal = "";

    this.disableCommoditySelect = false;
  }

  navigateToDashboard() {
    this.$router.push({
      name: "Dashboard",
    });
  }
}
