
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { NewsStore } from "@/store/modules";
import { News } from "@/store/model/news";

@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class AboutScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  news: News = {} as News;
  newsPageTitle = "";

  async created() {
    const newsID = this.$route.params.id;
    await this.fetchNewsByID(newsID);
  }

  async fetchNewsByID(newsId: any) {
    this.news = await NewsStore.getNewsByID({
      newsId: newsId,
    });
  }
}
