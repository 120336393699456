import { render, staticRenderFns } from "./CreateNewsScreen.vue?vue&type=template&id=5480c5ad"
import script from "./CreateNewsScreen.vue?vue&type=script&lang=ts"
export * from "./CreateNewsScreen.vue?vue&type=script&lang=ts"
import style0 from "./CreateNewsScreen.vue?vue&type=style&index=0&id=5480c5ad&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QForm,QInput,QEditor,QFile,QSkeleton,QCard,QImg,QCardActions,QDialog,QCardSection,QAvatar});qInstall(component, 'directives', {ClosePopup});
