<template>
  <label
    for="f_fe673072-d8e0-49cd-bed6-737dd988436e"
    class="
      q-field
      row
      no-wrap
      items-start
      q-input
      q-field--outlined q-field--float q-field--labeled q-field--dense
      q-validation-component
    "
    ><div class="q-field__inner relative-position col self-stretch">
      <div tabindex="-1" class="q-field__control relative-position row no-wrap">
        <div
          class="
            q-field__control-container
            col
            relative-position
            row
            no-wrap
            q-anchor--skip
          "
        >
          <!-- Allowed date feature was added and styles were changed in v-nepalidatepicker -->
          <!-- So this custom v-nepalidatepicker is being used -->
          <v-nepali-date-picker
            classValue="q-field__native q-placeholder"
            v-bind="$attrs"
            v-on="$listeners"
            v-model="syncedValue"
          />
          <div class="q-field__label no-pointer-events absolute ellipsis">
            {{ label }}
          </div>
        </div>
      </div>
    </div>
  </label>
</template>

<script>
import VNepaliDatePicker from "@/components/UIComponents/DatePicker/v-nepalidatepicker.vue";

export default {
  name: "NepaliDatePicker",
  components: { VNepaliDatePicker },
  props: {
    value: { type: String },
    label: { type: String },
  },
  computed: {
    syncedValue: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("input", e);
    },
  },
};
</script>

<style lang="sass" scoped>
::v-deep .calendar__header
  background: $primary
::v-deep .calendar__body
  .calendar__month
    select
      border-color: $primary
    button
      background: $primary
  .calendar__days
    .calendar__day
      background: $primary_light
      color: $primary
      &.diasbled
        background: lighten($primary_light, 50%)
      &.today
        color: white
        background: $secondary
      &.selected
        color: white
        background: $primary
::v-deep .calendar__footer
  button
    color: $primary
</style>
