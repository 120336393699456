<template>
  <div id="charts">
    <LineChart v-if="chartData" :chartData="chartData" />
  </div>
</template>
<script>
import PieChart from "./charts/PieChart.vue";
// import BarChart from "./charts/BarChart.vue";
import LineChart from "./charts/LineChart.vue";

export default {
  name: "App",
  components: {
    // PieChart,
    // BarChart,
    LineChart,
  },
  computed: {
    chartData: () => {
      return {
        labels: [
          "jan",
          "feb",
          "jan",
          "feb",
          "jan",
          "feb",
          "jan",
          "feb",
          "jan",
          "feb",
          "jan",
          "feb",
          "jan",
          "feb",
          "jan",
          "feb",
        ],
        datasets: [
          {
            label: "Maximum Price",
            data: [
              20, 31, 50, 9, 40, 6, 29, 39, 3, 1, 41, 25, 10, 7, 40, 9, 49, 19,
              2, 25,
            ],
            backgroundColor: "transparent",
            borderColor: "rgba(220,20,60,1)",
            pointBackgroundColor: "rgba(171, 71, 188, 1)",
          },
          {
            label: "Average Price",
            data: [
              5, 12, 25, 15, 4, 6, 20, 30, 30, 15, 45, 25, 10, 7, 14, 19, 41,
              20, 11,
            ],
            backgroundColor: "transparent",
            borderColor: "rgba(0,0,128, 1)",
            pointBackgroundColor: "rgba(171, 71, 188, 1)",
          },
          {
            label: "Minimum Price",
            data: [
              41, 24, 29, 4, 7, 60, 20, 35, 45, 11, 35, 20, 14, 7, 44, 6, 49,
              10, 21,
            ],
            backgroundColor: "transparent",
            borderColor: "rgba(0,255,255, 1)",
            pointBackgroundColor: "rgba(171, 71, 188, 1)",
          },
        ],
      };
    },
  },
};
</script>

<style>
#charts {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
