
import { Component, Vue, Prop } from "vue-property-decorator";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import exportingInit from "highcharts/modules/exporting";
import Stock from "highcharts/modules/stock";
exportingInit(Highcharts);
Stock(Highcharts);

@Component({
  components: {
    highchart: Chart,
  },
})
export default class BaseChart extends Vue {
  $refs!: {
    // eslint-disable-next-line
    highchart: InstanceType<typeof Chart>;
  };
  @Prop({ required: true })
  // eslint-disable-next-line
  chartData!: any;
  chartOptions2 = {
    title: { text: "Variation of Price Throught the Time" },
    rangeSelector: { selected: 1 },
    series: [
      {
        name: "Wholesale Average Price",
        data: [
          [1638296100000, 70],
          [1638382500000, 70],
          [1638468900000, 70],
          [1638728100000, 70],
          [1638814500000, 70],
          [1638987300000, 70],
          [1639073700000, 70],
          [1639332900000, 290],
          [1639419300000, 290],
        ],
        tooltip: { valueDecimals: 2 },
      },
      {
        name: "Wholesale Minimum Price",
        data: [
          [1638296100000, 60],
          [1638382500000, 60],
          [1638468900000, 60],
          [1638728100000, 60],
          [1638814500000, 60],
          [1638987300000, 60],
          [1639073700000, 60],
          [1639332900000, 280],
          [1639419300000, 280],
        ],
        tooltip: { valueDecimals: 2 },
      },
      {
        name: "Wholesale Maximum Price",
        data: [
          [1638296100000, 80],
          [1638382500000, 80],
          [1638468900000, 80],
          [1638728100000, 80],
          [1638814500000, 80],
          [1638987300000, 80],
          [1639073700000, 80],
          [1639332900000, 300],
          [1639419300000, 300],
        ],
        tooltip: { valueDecimals: 2 },
      },
    ],
    plotOptions: {
      series: {
        label: { connectorAllowed: false },
        marker: { enabled: true, radius: 4, symbol: "circle" },
      },
    },
  };
  get chartOptions() {
    return {
      // chart: this.chartData.chart,
      // ...this.chartData,
      yAxis: [
        {
          labels: {
            align: "left",
          },
          height: "80%",
          resize: {
            enabled: true,
          },
        },
        {
          labels: {
            align: "left",
          },
          top: "80%",
          height: "20%",
          offset: 0,
        },
      ],
      series: this.chartData.series,
      chart: { type: "spline" },
      title: { text: "Variation of Price Throught the Time" },
      rangeSelector: { selected: 1 },
      // plotOptions: this.chartData.plotOptions,
      // title: this.chartData.title,

      credits: {
        enabled: false,
      },

      tooltip: {
        shared: true,
      },

      plotOptions: {
        series: {
          label: { connectorAllowed: false },
          marker: { enabled: true, radius: 4, symbol: "circle" },
        },
      },
      // legend: {
      //   align: "center",
      //   verticalAlign: "bottom",
      //   y: 0,
      //   padding: 0,
      //   itemMarginTop: 0,
      //   itemMarginBottom: 0,
      //   itemStyle: {
      //     lineHeight: "0px",
      //   },
      // },
    };
  }

  mounted() {
    window.addEventListener("resize", this.redraw);
    this.redraw();
  }
  redraw() {
    this.$refs.highchart.chart.update((this as any).chartOptions, true);
    this.$refs.highchart.chart.setSize();
  }
}
