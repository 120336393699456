"use strict";

const MONTHS_EN = [
  "Baisakh",
  "Jestha",
  "Asar",
  "Shrawan",
  "Bhadra",
  "Aswin",
  "Kartik",
  "Mangsir",
  "Poush",
  "Magh",
  "Falgun",
  "Chaitra",
];
const MONTHS_SHORT_EN = [
  "Bai",
  "Jes",
  "Asa",
  "Shr",
  "Bhd",
  "Asw",
  "Kar",
  "Man",
  "Pou",
  "Mag",
  "Fal",
  "Cha",
];
const MONTHS_NP = [
  "बैशाख",
  "जेठ",
  "असार",
  "श्रावण",
  "भाद्र",
  "आश्विन",
  "कार्तिक",
  "मंसिर",
  "पौष",
  "माघ",
  "फाल्गुण",
  "चैत्र",
];
const MONTHS_SHORT_NP = [
  "बै",
  "जे",
  "अ",
  "श्रा",
  "भा",
  "आ",
  "का",
  "मं",
  "पौ",
  "मा",
  "फा",
  "चै",
];
const NUM_NP = ["०", "१", "२", "३", "४", "५", "६", "७", "८", "९"];
const WEEKDAYS_SHORT_EN = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const WEEKDAYS_LONG_EN = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const WEEKDAYS_SHORT_NP = ["आइत", "सोम", "मंगल", "बुध", "बिहि", "शुक्र", "शनि"];
const WEEKDAYS_LONG_NP = [
  "आइतबार",
  "सोमबार",
  "मंगलबार",
  "बुधबार",
  "बिहिबार",
  "शुक्रबार",
  "शनिबार",
];

const pad = (n) => {
  if (n < 10) {
    return "0" + n;
  }
  return "" + n;
};

const npDigit = (str) => {
  let res = "";
  for (let i = 0; i < str.length; i += 1) {
    res += NUM_NP[str.charCodeAt(i) - 48];
  }
  return res;
};

const yearEn = (size) => (date) => {
  if (size <= 2) {
    return String(date.year).substring(2);
  }
  if (size === 3) {
    return String(date.year).substring(1);
  }
  return date.year;
};

const yearNp = (size) => (date) => {
  if (size <= 2) {
    return npDigit(String(date.year).substring(2));
  }
  if (size === 3) {
    return npDigit(String(date.year).substring(1));
  }
  return npDigit(String(date.year));
};

const monthEn = (size) => (date) => {
  if (size === 1) {
    return String(date.month + 1);
  }
  if (size === 2) {
    return pad(date.month + 1);
  }
  if (size === 3) {
    return MONTHS_SHORT_EN[date.month];
  }
  return MONTHS_EN[date.month];
};

const monthNp = (size) => (date) => {
  if (size === 1) {
    return npDigit(String(date.month + 1));
  }
  if (size === 2) {
    return npDigit(pad(date.month + 1));
  }
  if (size === 3) {
    return MONTHS_SHORT_NP[date.month];
  }
  return MONTHS_NP[date.month];
};

const dateEn = (size) => (date) => {
  if (size === 1) {
    return String(date.day);
  }
  if (size === 2) {
    return pad(date.day);
  }
  if (size === 3) {
    return WEEKDAYS_SHORT_EN[date.getDay()];
  }
  return WEEKDAYS_LONG_EN[date.getDay()];
};

const dateNp = (size) => (date) => {
  if (size === 1) {
    return npDigit(String(date.day));
  }
  if (size === 2) {
    return npDigit(pad(date.day));
  }
  if (size === 3) {
    return WEEKDAYS_SHORT_NP[date.getDay()];
  }
  return WEEKDAYS_LONG_NP[date.getDay()];
};

const pass = (seq) => () => seq;

const fn = {
  Y: yearEn,
  y: yearNp,
  M: monthEn,
  m: monthNp,
  D: dateEn,
  d: dateNp,
};

const isSpecial = (ch) => ch in fn;

const tokenize = (formatStr) => {
  let inQuote = false;
  let seq = "";
  let special = "";
  let specialSize = 0;

  const tokens = [];

  for (let i = 0; i < formatStr.length; i += 1) {
    const ch = formatStr[i];
    if (ch === special) {
      specialSize += 1;
      continue;
    }

    // Time to process special
    if (special !== "") {
      tokens.push(fn[special](specialSize));
      special = "";
      specialSize = 0;
    }

    if (ch === '"') {
      inQuote = !inQuote;
      continue;
    }

    if (!isSpecial(ch) || inQuote) {
      seq += ch;
    } else {
      // got a special character
      if (seq) {
        tokens.push(pass(seq));
        seq = "";
      }

      special = ch;
      specialSize = 1;
    }
  }

  if (seq) {
    tokens.push(pass(seq));
  } else if (special) {
    tokens.push(fn[special](specialSize));
  }

  return tokens;
};

const format = (nepaliDate, formatStr) =>
  tokenize(formatStr)
    .map((f) => f(nepaliDate))
    .join("");

export default format;
