
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ContactPerson extends Vue {
  show = false;
  itemList = [] as any;
  hideButton = false;
  focalPersons = [
    {
      name: "राम बहादुर खत्री",
      designation: "बजार व्यवस्थापक",
      img: "ram.jpg",
    },
    {
      name: "शंकर ढकाल",
      designation: "लेखा अधिकृत",
      img: "placeholder.jpg",
    },
    {
      name: "बिकास पाण्डेय",
      designation: "लेखा सहायक",
      img: "bikashp.jpg",
    },

    {
      name: "सिता पौड्याल",
      designation: "कार्यालय सहायक",
      img: "sitap.jpg",
    },
    {
      name: "राधा भुर्तेल",
      designation: "कार्यालय सहायक",
      img: "radhab.jpg",
    },
    {
      name: "खुमानन्द आचार्य",
      designation: "सुरक्षा गार्ड",
      img: "khumanand.jpg",
    },
    {
      name: "प्रकाश मगर",
      designation: "सुरक्षा गार्ड",
      img: "prakashm.jpg",
    },
    {
      name: "सन्तोेष भण्डारी",
      designation: "सुरक्षा गार्ड",
      img: "santosh.jpg",
    },
    {
      name: "लक्ष्मण भण्डारी",
      designation: "सुरक्षा गार्ड",
      img: "laxman.jpg",
    },
    {
      name: "ज्ञान बहादुर कुँवर",
      designation: "सुरक्षा गार्ड",
      img: "gyank.jpg",
    },
    {
      name: "सेमकला रेग्मी",
      designation: "कार्यालय सहयोगी",
      img: "semkala.jpg",
    },
    {
      name: "सतमान दमाई",
      designation: "सरसफाई कर्मी",
      img: "satman.jpg",
    },
    {
      name: "शुभम परियार",
      designation: "सरसफाई कर्मी",
      img: "shuvam.jpg",
    },
  ];

  created() {
    let routeName = this.$route.name;
    console.log(routeName);
    if (routeName == "Home") {
      for (let i = 0; i < 4; i++) {
        this.itemList.push(this.focalPersons[i]);
      }
      this.hideButton = false;
    }

    if (routeName == "Contact") {
      this.itemList = this.focalPersons;
      this.hideButton = true;
    }
  }

  navigateToContactScreen() {
    this.$router.push({
      name: "Contact",
    });
  }
}
