
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import AppFooter from "@/components/UIComponents/AppFooter.vue";
import { MarketStore, NewsStore, TransportStore } from "@/store/modules";
import colors from 'quasar/src/utils/colors.js';;
import ref from "vue";

@Component({
  components: { ContentHeader, AppFooter },
  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class TransportScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  searchText = null;
  openDeleteDialog = false;
  newsId = "";

  currentPage = 1;
  tableLoading = false;
  totalPages = 0;

  created() {
    this.tableLoading = true;
    this.fetchPaginatedNewsList();
    this.tableLoading = false;
    console.log(colors.getPaletteColor("primary"));
  }

  async fetchPaginatedNewsList() {
    let filters: any = {};
    filters["market"] = MarketStore.MARKETPLACE_ID;

    if (this.searchText) {
      await NewsStore.getPaginatedNewsList({
        page: 1,
        searchString: this.searchText,
        filters: filters,
      });
      this.currentPage = 1;
    } else {
      await NewsStore.getPaginatedNewsList({
        page: this.currentPage,
        filters: filters,
      });
    }

    this.paginatedNewsList;
    this.totalPages = this.paginatedNewsList.total_pages;
    this.currentPage = this.paginatedNewsList.page;
  }

  get paginatedNewsList() {
    console.log(NewsStore.PaginatedNewsList);
    return NewsStore.PaginatedNewsList;
  }

  dataColumn = [
    {
      name: "date_created",
      align: "left",
      label: "Date",
      field: "date_created",
      sortable: true,
    },
    {
      name: "title",
      required: true,
      label: "Title",
      align: "left",
      field: (row: any) => row.title,
      sortable: true,
    },

    // { name:'short_desc', align: 'left', label: 'Description ', field: 'short_desc', sortable: true },

    {
      name: "sd_image",
      align: "left",
      label: "Image",
      field: "sd_image",
      sortable: true,
    },
    { name: "id", align: "left", label: "Action", field: "id", sortable: true },
  ];

  navigateToCreateNews() {
    this.$router.push({
      name: "CreateNews",
    });
  }

  navigateToEditNews(id: any) {
    this.$router.push({
      name: "EditNews",
      params: {
        id,
      },
    });
  }

  navigateToDashboard() {
    this.$router.push({
      name: "Dashboard",
    });
  }

  toggleDeleteDialog(newsId: any) {
    let state = this.openDeleteDialog;
    this.openDeleteDialog = !state;
    this.newsId = newsId;
  }

  async deleteNewsByID() {
    await NewsStore.deleteNewsByID(this.newsId)
      .then(() => {
        this.$q.notify({
          message: "Sucessfully deleted",
          color: "info",
        });
        this.fetchPaginatedNewsList();

        let index = this.paginatedNewsList.results
          .map((x) => {
            return x.id;
          })
          .indexOf(this.newsId);
      })
      .catch(() => {
        this.$q.notify({
          message: "Error occured while performing this action",
          color: "negative",
        });
      });
  }
}
