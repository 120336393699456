
import { Component, Vue } from "vue-property-decorator";
import { MarketStore, TransportStore } from "@/store/modules";
import { eventHub } from "@/utils/eventHub";
import { Base, getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import { Product, ProductPrice } from "@/store/model/market";

import date from 'quasar/src/utils/date.js';;

import { BSToAD } from "bikram-sambat-js";

import NepaliDatePicker from "@/components/UIComponents/DatePicker/NepaliDatePicker.vue";

import exportFile from 'quasar/src/utils/export-file.js';;
import { nepaliUnicodeNumber } from "@/utils/date";
import { QTablePagination } from "@/store/model/page";

@Component({
  components: { ContentHeader, NepaliDatePicker },

  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class PriceReports extends Vue {
  headerBackgroundUrl = "about.jpg";
  ProductListDownlading = "";
  isHomeScreen = true;
  tableLoading = false;
  asOf = "";
  currentDate = "";
  startDate = "";
  endDate = "";
  currentPage: any = "";
  totalPages: any = "";
  commodityCategoryModel = "";

  get columns() {
    return [
      {
        name: "product_name",
        required: true,
        label: `${this.$i18n.t("commodity")}`,
        align: "left",
        field: (row: ProductPrice): string => {
          return this.$i18n.locale == "ne"
            ? row.product_name_ne
              ? row.product_name_ne
              : row.product_name
            : row.product_name;
        },
      },

      {
        name: "all_country",
        align: "left",
        label: `${this.$i18n.t("country")}`,
        field: "all_country",
      },
      {
        name: "all_district",
        align: "left",
        label: `${this.$i18n.t("district")}`,

        field: "all_district",
      },
      {
        name: "all_quantity",
        align: "right",
        label: `${this.$i18n.t("quantity")} (${this.$i18n.t("metricTonne")})`,
        field: "all_quantity",
        format: (val: string | number): string =>
          `${nepaliUnicodeNumber(val)} ${this.$i18n.t("metricTonne")}`,
      },
      {
        name: "avg_prices",
        align: "right",
        label: `${this.$i18n.t("avgPrice")} (${this.$i18n.t("Rs.")})`,
        field: "avg_prices",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
      {
        name: "total_transaction",
        align: "right",
        label: `${this.$i18n.t("transaction")} (${this.$i18n.t(
          "inThousands"
        )})`,
        field: "total_transaction",
        format: (val: string | number): string =>
          `${this.$i18n.locale == "ne" ? "रू." : "Rs."} ${nepaliUnicodeNumber(
            val
          )}`,
      },
    ];
  }

  get pagination() {
    return TransportStore.TransactionReportTablePagination;
  }

  set pagination(data: QTablePagination) {
    TransportStore.setTransactionReportPagination(data);
  }
  get commodityCategories() {
    const categoryList = JSON.parse(JSON.stringify(MarketStore.CategoryList));
    categoryList.unshift({
      sid: "",
      name: "All",
      name_ne: "सबै",
    });
    return categoryList;
  }

  get marketDates() {
    return MarketStore.MarketDates;
  }

  loadReportData() {
    this.pagination.page = 1;
    this.onRequest({
      pagination: this.pagination,
      filter: "",
    });
  }

  async created() {
    await MarketStore.getMarketPriceDates();
    if (this.marketDates.length > 0) {
      this.currentDate = this.marketDates[0];
      this.startDate = this.marketDates[0];
      this.endDate = this.marketDates[0];
    }
    this.loadReportData();
    await MarketStore.getProductCategoryList();

    // assigning button visibility if component appears out of homescreen
    if (this.$route.name != "Home") {
      this.isHomeScreen = false;
    } else {
      this.isHomeScreen = true;
    }
  }

  //eslint-disable-next-line
  async onRequest(props: any) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination;
    const filter = props.filter;
    this.tableLoading = true;

    const ordering = `${descending ? "-" : ""}${sortBy}`;

    const queryParams = {
      page: page,
      search: filter,
      ordering: ordering,
      pageSize: rowsPerPage,
      commodity: this.commodityCategoryModel,
      startDate: this.startDate,
      endDate: this.endDate,
    };
    await TransportStore.getPaginatedTransactionDateRangeList(queryParams);
    this.tableLoading = false;

    this.pagination = {
      page: this.paginatedTransactionList.current_page,
      rowsPerPage: this.paginatedTransactionList.page_size,
      rowsNumber: this.paginatedTransactionList.total_objects,
      sortBy: sortBy,
      descending: descending,
    };
  }

  get paginatedTransactionList() {
    return TransportStore.PaginatedTransactionReportList;
  }

  async downloadProductList() {
    const url = `${Action.ExportTransactionDateRangeList.replace(
      "<marketId>",
      MarketStore.MARKETPLACE_ID
    )
      .replace("<nepStartDate>", this.startDate)
      .replace("<nepEndDate>", this.endDate)}`;

    let query = "";

    if (this.commodityCategoryModel != "") {
      query += `?product__category=${this.commodityCategoryModel}`;
    }
    const fileData = await getApi(url, query, { responseType: "blob" });
    exportFile(
      "Transaction.xlsx",
      fileData,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
  }

  navigateToPriceMonitorScreen() {
    this.$router.push({
      name: "PriceMonitor",
    });
  }
  allowedStartDate(dt: any) {
    const endDate = new Date(BSToAD(this.endDate));
    const diff = date.getDateDiff(dt.timestamp, endDate, "days");
    return diff <= 0;
  }
  allowedEndDate(dt: any) {
    const startDate = new Date(BSToAD(this.startDate));
    const diff = date.getDateDiff(startDate, dt.timestamp, "days");
    return diff <= 0;
  }
}
