
import { MarketStore } from "@/store/modules";
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    results: Array,
    loading: Boolean,
  },
})
export default class UserTable extends Vue {
  pagination = {
    rowsPerPage: 0,
  };

  tableLoading = false;

  dataColumn = [
    {
      name: "index",
      label: "#",
      field: "index",
    },

    {
      name: "date_joined",
      align: "left",
      label: "सामेल भएको मिति",
      field: "date_joined",
      sortable: true,
    },

    {
      name: "full_name",
      align: "left",
      label: "व्यक्तिको नाम",
      field: "full_name",
      sortable: true,
    },

    {
      name: "username",
      label: "युजरनेम",
      align: "left",
      field: "username",
      sortable: true,
      sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
    },

    {
      name: "email",
      label: "इमेल",
      align: "left",
      field: "email",
      sortable: true,
      sort: (a: any, b: any) => parseInt(a, 10) - parseInt(b, 10),
    },

    {
      name: "role",
      align: "left",
      label: "प्रयोगकर्ताको प्रकार",
      field: "quantity",
      sortable: true,
    },

    { name: "id", align: "left", label: "कार्य", field: "id", sortable: true },
  ];

  async created() {
    // this.fetchMarketSummary();
  }

  async fetchMarketSummary() {
    let market_id = MarketStore.MARKETPLACE_ID;

    let filters: any = {};
    filters["market_id"] = market_id;

    await MarketStore.getMarketSummary({
      filters: filters,
    });
  }

  get marketSummary() {
    return MarketStore.MarketSummary;
  }
}
