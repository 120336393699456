import { render, staticRenderFns } from "./TraderDetailScreen.vue?vue&type=template&id=4a385d16"
import script from "./TraderDetailScreen.vue?vue&type=script&lang=ts"
export * from "./TraderDetailScreen.vue?vue&type=script&lang=ts"
import style0 from "./TraderDetailScreen.vue?vue&type=style&index=0&id=4a385d16&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QInnerLoading from 'quasar/src/components/inner-loading/QInnerLoading.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QFab from 'quasar/src/components/fab/QFab.js';
import QFabAction from 'quasar/src/components/fab/QFabAction.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QAvatar,QSkeleton,QCard,QCardSection,QSeparator,QChip,QInnerLoading,QPageSticky,QFab,QFabAction,QDialog,QCardActions});qInstall(component, 'directives', {ClosePopup});
