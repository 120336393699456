
import { Component, Vue } from "vue-property-decorator";
import { eventHub } from "@/utils/eventHub";
import { FilterPriceMonitor } from "@/store/model/utils";
import { MarketStore } from "@/store/modules";
import { filter } from "vue/types/umd";

@Component
export default class FilterCard extends Vue {
  loadingFilterBtn = false;
  filterPriceMonitor: FilterPriceMonitor = {
    product: "",
    date: "",
    product_category: "",
    search: "",
  } as FilterPriceMonitor;
  currentPage = 1;

  commoditySelectDisabled = true;
  commodityCategory = "" as any;

  created() {
    this.fetchCommoditycategory();
    eventHub.$on("filterProductList", this.filterProductList);
  }

  get date() {
    console.log(MarketStore.date);
    return MarketStore.date;
  }

  filterProductList() {
    // console.log("cp", current_page);

    // if (current_page) {
    // this.currentPage = current_page;
    // }

    if (this.$q.screen.sm || this.$q.screen.lt.sm) {
      eventHub.$emit("toggleFilterCard");
    }

    console.log("FPM -> ", this.filterPriceMonitor);
    this.loadingFilterBtn = true;
    console.log(this.loadingFilterBtn);

    eventHub.$emit(
      "emitPaginatedProductPriceList",
      this.filterPriceMonitor,
      this.currentPage
    );

    this.loadingFilterBtn = false;
  }

  beforeDestroy() {
    // eventHub.$off('emitPaginatedProductPriceList')
    // eventHub.$off('toggleFilterCard')
  }

  async fetchCommodityList() {
    let filters: any = {};
    filters["category__id"] = (
      this as any
    ).filterPriceMonitor.product_category.sid;
    this.commoditySelectDisabled = false;
    await MarketStore.getProductList({
      filters: filters,
    });
  }

  async fetchCommoditycategory() {
    await MarketStore.getProductCategoryList();
  }

  async assignDateToStore() {
    MarketStore.changeDate(this.filterPriceMonitor.date);
    await this.date;
  }

  get categories() {
    const categoryList = JSON.parse(JSON.stringify(MarketStore.CategoryList));
    categoryList.unshift({
      sid: "",
      name: "All",
      name_ne: "सबै",
    });
    return categoryList;
  }

  get commodities() {
    const res = MarketStore.ProductList;
    console.log(res);
    // res.unshift({
    //   id: "",
    //   category_id: "0",
    //   marketplace_id: "0",
    //   name: "All",
    //   product_price: "0",
    // });
    return res;
  }
}
