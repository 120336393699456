
import { Vue, Component } from "vue-property-decorator";
import ContentHeader from "@/components/UIComponents/ContentHeader.vue";
import PriceMonitor from "@/components/UIComponents/PriceMonitor.vue";
import FilterCard from "@/components/UIComponents/FilterCard.vue";
import { MarketStore } from "@/store/modules";
import { Category } from "@/store/model/market";
import { FilterPriceMonitor } from "@/store/model/utils";
import { eventHub } from "@/utils/eventHub";
import event from 'quasar/src/utils/event.js';;

@Component({
  components: { ContentHeader, PriceMonitor, FilterCard },

  //   metaInfo(): MetaInfo {
  //     return {
  //       titleTemplate: 'Marketplace Home',
  //       meta: [
  //         { charset: "utf-8" },
  //         { name: "viewport", content: "width=device-width, initial-scale=1" }
  //       ]
  //     };
  //   }
})
export default class PriceMonitorScreen extends Vue {
  headerBackgroundUrl = "about.jpg";
  currentPage = 1;
  totalPages = 0;
  dateModel = "";
  loadingFilterBtn = false;

  commodity = "" as any;

  filterCardVisible = false;

  asOf = "";

  async created() {
    await MarketStore.getPaginatedProductPriceList();
    eventHub.$on("toggleFilterCard", this.toggleFilterCard);
    console.log("paginated tot_pg", this.paginatedProductPriceList.total_pages);
    this.totalPages = this.paginatedProductPriceList.total_pages;
    this.asOf = this.paginatedProductPriceList.results[0].date;
  }

  get paginatedProductPriceList() {
    return MarketStore.PaginatedProductPriceList;
  }

  toggleFilterCard() {
    let bool = this.filterCardVisible;
    this.filterCardVisible = !bool;
  }

  filterProductList() {
    eventHub.$emit("filterProductList", this.currentPage);
  }
}
