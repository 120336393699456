<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,

  mounted() {
    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");
    this.renderChart(
      {
        labels: ["Maximum", "Average", "Minimum"],
        datasets: [
          {
            backgroundColor: [this.gradient, this.gradient2, "#00D8FF"],
            data() {
              return {
                responseData: [],
              };
            },
          },
        ],
      },
      { responsive: true, maintainAspectRatio: false }
    );
  },

  async created() {
    var response = await fetch(
      "http://127.0.0.1:8000/api/market/raw_data/transport_market/?market_id=1"
    );
    this.responseData = await response.data.json();
    return this.responseData.data;
  },
};
</script>
