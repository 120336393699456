import { render, staticRenderFns } from "./ReportsScreen.vue?vue&type=template&id=3e217531&scoped=true"
import script from "./ReportsScreen.vue?vue&type=script&lang=ts"
export * from "./ReportsScreen.vue?vue&type=script&lang=ts"
import style0 from "./ReportsScreen.vue?vue&type=style&index=0&id=3e217531&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e217531",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QCard,QCardSection});
